var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { inject, injectable } from "tsyringe";
import { IFieldTypeDataSource } from "@/data/data-sources/field-type/field-type-data-source";
import { FieldTypeCache } from "@/data/cache/field-type-cache";
import { FieldTypeLinkMapper } from "@/data/data-sources/link-mapper";
export let FieldTypeRepositoryDefaultImpl = class FieldTypeRepositoryDefaultImpl {
    fieldTypeDataSource;
    fieldTypeCache;
    linkMapper;
    constructor(fieldTypeDataSource, fieldTypeCache, linkMapper) {
        this.fieldTypeDataSource = fieldTypeDataSource;
        this.fieldTypeCache = fieldTypeCache;
        this.linkMapper = linkMapper;
    }
    async readAll() {
        if (!this.fieldTypeCache.hasCachedItems()) {
            const link = this.linkMapper.generateLink("read-all");
            const result = await this.fieldTypeDataSource.readAll({
                url: link.href,
                method: link.method
            });
            this.fieldTypeCache.setItems(result);
        }
        return this.fieldTypeCache.getAllItems().map(value => value.fieldType);
    }
};
FieldTypeRepositoryDefaultImpl = __decorate([
    injectable(),
    __param(0, inject("FieldTypeDataSource")),
    __param(1, inject("FieldTypeCache")),
    __param(2, inject("FieldTypeLinkMapper")),
    __metadata("design:paramtypes", [typeof (_a = typeof IFieldTypeDataSource !== "undefined" && IFieldTypeDataSource) === "function" ? _a : Object, typeof (_b = typeof FieldTypeCache !== "undefined" && FieldTypeCache) === "function" ? _b : Object, typeof (_c = typeof FieldTypeLinkMapper !== "undefined" && FieldTypeLinkMapper) === "function" ? _c : Object])
], FieldTypeRepositoryDefaultImpl);
