var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { inject, injectable } from "tsyringe";
import { ICustomerTypeDataSource } from "@/data/data-sources/customer/customer-type-data-source";
import { CustomerTypeCache } from "@/data/cache/customer-type-cache";
import { CustomerTypeLinkMapper } from "@/data/data-sources/link-mapper";
export let CustomerTypeRepositoryDefaultImpl = class CustomerTypeRepositoryDefaultImpl {
    customerTypeDataSource;
    customerTypeCache;
    linkMapper;
    constructor(customerTypeDataSource, customerTypeCache, linkMapper) {
        this.customerTypeDataSource = customerTypeDataSource;
        this.customerTypeCache = customerTypeCache;
        this.linkMapper = linkMapper;
    }
    async readAll() {
        if (!this.customerTypeCache.hasCachedItems()) {
            const link = this.linkMapper.generateLink("read-all");
            const result = await this.customerTypeDataSource.readAll({
                url: link.href,
                method: link.method
            });
            this.customerTypeCache.setItems(result);
        }
        return this.customerTypeCache.getAllItems().map(value => value.customerType);
    }
};
CustomerTypeRepositoryDefaultImpl = __decorate([
    injectable(),
    __param(0, inject("CustomerTypeDataSource")),
    __param(1, inject("CustomerTypeCache")),
    __param(2, inject("CustomerTypeLinkMapper")),
    __metadata("design:paramtypes", [typeof (_a = typeof ICustomerTypeDataSource !== "undefined" && ICustomerTypeDataSource) === "function" ? _a : Object, typeof (_b = typeof CustomerTypeCache !== "undefined" && CustomerTypeCache) === "function" ? _b : Object, typeof (_c = typeof CustomerTypeLinkMapper !== "undefined" && CustomerTypeLinkMapper) === "function" ? _c : Object])
], CustomerTypeRepositoryDefaultImpl);
