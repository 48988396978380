import { defineStore } from "pinia";
import { container } from "tsyringe";
import NProgress from "nprogress";
export const useCustomerStore = defineStore("customer", {
    state: () => {
        return {
            error: undefined,
            customers: undefined,
            search: undefined
        };
    },
    actions: {
        async load(customerTypeId) {
            const customerRepository = container.resolve("customer-repository");
            NProgress.start();
            await customerRepository.readAll(this.search, customerTypeId).then(value => {
                this.clearError();
                this.customers = value;
            }).catch(reason => {
                this.error = reason;
            }).finally(() => {
                NProgress.done();
            });
        },
        async create(email, name, externalLink, customerTypeId) {
            const customerRepository = container.resolve("customer-repository");
            NProgress.start();
            await customerRepository.create(email, name, externalLink, customerTypeId).then(value => {
                this.clearError();
                if (this.customers) {
                    this.customers = [value, ...this.customers];
                }
                else {
                    this.customers = [value];
                }
            }).catch(reason => {
                this.error = reason;
            }).finally(() => {
                NProgress.done();
            });
        },
        async update(id, email, name, externalLink, customerTypeId) {
            const customerRepository = container.resolve("customer-repository");
            NProgress.start();
            await customerRepository.update(id, email, name, externalLink, customerTypeId).then(value => {
                this.clearError();
                if (this.customers) {
                    const indexOfOldItem = this.customers.findIndex(customer => customer.id === id);
                    if (indexOfOldItem !== -1) {
                        this.customers[indexOfOldItem] = value;
                    }
                }
                else {
                    this.customers = [value];
                }
            }).catch(reason => {
                this.error = reason;
            }).finally(() => {
                NProgress.done();
            });
        },
        async delete(id) {
            const customerRepository = container.resolve("customer-repository");
            NProgress.start();
            await customerRepository.delete(id).then(() => {
                this.clearError();
                if (this.customers) {
                    const customerIndex = this.customers.findIndex(customer => customer.id === id);
                    this.customers.splice(customerIndex, 1);
                }
            }).catch(reason => {
                this.error = reason;
            }).finally(() => {
                NProgress.done();
            });
        },
        clearError() {
            this.error = undefined;
        }
    },
    getters: {
        isLoaded: (state) => state.customers !== undefined && state.error === undefined,
        hasError: (state) => state.error !== undefined,
        customerById: (state) => {
            return async (id) => {
                const cachedCustomer = state.customers?.find(customer => customer.id === id);
                if (cachedCustomer) {
                    return cachedCustomer;
                }
                else {
                    const customerRepository = container.resolve("customer-repository");
                    return await customerRepository.readSingle(id);
                }
            };
        }
    }
});
