import 'reflect-metadata';
import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import router from "@/router";
import { createPinia } from "pinia";
import { container } from "tsyringe";
import vue3GoogleLogin from 'vue3-google-login';
import { CustomerTypeRepositoryDefaultImpl } from "@/data/repositories/customer/customer-type-repository-default-impl";
import { FieldTypeRepositoryDefaultImpl } from "@/data/repositories/field-type/field-type-repository-default-impl";
import { CustomerRepositoryDefaultImpl } from "@/data/repositories/customer/customer-repository-default-impl";
import { TypeRepositoryDefaultImpl } from "@/data/repositories/type/type-repository-default-impl";
import { ItemsListRepositoryDefaultImpl } from "@/data/repositories/items-list/items-list-repository-default-impl";
import { SearchItemRepositoryDefaultImpl } from "@/data/repositories/search-item/search-item-repository-default-impl";
import { ItemRepositoryDefaultImpl } from "@/data/repositories/item/item-repository-default-impl";
import { DashboardRepositoryDefaultImpl } from "@/data/repositories/dashboard/dashboard-repository-default-impl";
import { SymvaroLaravelAuthHttpClient, SymvaroLaravelHttpClient } from "@/data/data-sources/http-client";
import { DashboardDataSourceLaravelImpl } from "@/data/data-sources/dashboard/dashboard-data-source-laravel-impl";
import { ReportDataSourceLaravelImpl } from "@/data/data-sources/report/report-data-source-laravel-impl";
import { ReportRepositoryDefaultImpl } from "@/data/repositories/report/report-repository-default-impl";
import { CategoryDataSourceLaravelImpl } from "@/data/data-sources/category/category-data-source-laravel-impl";
import { CategoryRepositoryDefaultImpl } from "@/data/repositories/category/category-repository-default-impl";
import { CategoryCache } from "@/data/cache/category-cache";
import { CustomerCache } from "@/data/cache/customer-cache";
import { CustomerDataSourceLaravelImpl } from "@/data/data-sources/customer/customer-data-source-laravel-impl";
import { CustomerTypeDataSourceLaravelImpl } from "@/data/data-sources/customer/customer-type-data-source-laravel-impl";
import { FieldTypeDataSourceLaravelImpl } from "@/data/data-sources/field-type/field-type-data-source-laravel-impl";
import { SymvaroLaravelAuthDataSource } from "@/data/data-sources/auth/auth-data-source";
import { AuthRepositoryGoogleSignInImpl } from "@/data/repositories/auth/auth-repository-google-sign-in-impl";
import { CustomerLinkCache, ItemLinkCache, TypeLinkCache } from "@/data/cache/link-cache";
import { CustomerDaoLinkMapper, CustomerTypeLinkMapper, DashboardLinkMapper, FieldTypeLinkMapper, FileLinkMapper, HistoryLinkMapper, ItemDaoLinkMapper, ItemsListItemDaoLinkMapper, ReportLinkMapper, SearchItemDaoLinkMapper, TypeDaoLinkMapper } from "@/data/data-sources/link-mapper";
import { CustomerTypeCache } from "@/data/cache/customer-type-cache";
import { ItemDataSourceLaravelImpl } from "@/data/data-sources/item/item-data-source-laravel-impl";
import { ItemCache } from "@/data/cache/item-cache";
import { SearchItemDataSourceLaravelImpl } from "@/data/data-sources/search-item/search-item-data-source-laravel-impl";
import { ItemsListDataSourceLaravelImpl } from "@/data/data-sources/items-list/items-list-data-source-laravel-impl";
import { TypeDataSourceLaravelImpl } from "@/data/data-sources/type/type-data-source-laravel-impl";
import { TypeCache } from "@/data/cache/type-cache";
import { FieldTypeCache } from "@/data/cache/field-type-cache";
import { HistoryDataSourceLaravelImpl } from "@/data/data-sources/history/history-data-source-laravel-impl";
import { HistoryRepositoryDefaultImpl } from "@/data/repositories/history/history-repository-default-impl";
import { FileUploadRepositoryDefaultImpl } from "@/data/repositories/file/file-upload-repository-default-impl";
container.registerSingleton("HttpClient", SymvaroLaravelHttpClient);
container.registerSingleton("AuthHttpClient", SymvaroLaravelAuthHttpClient);
// Caches
container.register("CategoryCache", { useClass: CategoryCache });
container.register("CustomerCache", { useClass: CustomerCache });
container.register("CustomerLinkCache", { useClass: CustomerLinkCache });
container.register("CustomerTypeCache", { useClass: CustomerTypeCache });
container.register("ItemLinkCache", { useClass: ItemLinkCache });
container.register("ItemCache", { useClass: ItemCache });
container.register("TypeCache", { useClass: TypeCache });
container.register("TypeLinkCache", { useClass: TypeLinkCache });
container.register("FieldTypeCache", { useClass: FieldTypeCache });
// Mappers
container.register("CustomerDaoLinkMapper", { useClass: CustomerDaoLinkMapper });
container.register("CustomerTypeLinkMapper", { useClass: CustomerTypeLinkMapper });
container.register("ItemDaoLinkMapper", { useClass: ItemDaoLinkMapper });
container.register("SearchItemDaoLinkMapper", { useClass: SearchItemDaoLinkMapper });
container.register("ItemsListItemDaoLinkMapper", { useClass: ItemsListItemDaoLinkMapper });
container.register("TypeDaoLinkMapper", { useClass: TypeDaoLinkMapper });
container.register("FieldTypeLinkMapper", { useClass: FieldTypeLinkMapper });
container.register("HistoryLinkMapper", { useClass: HistoryLinkMapper });
container.register("ReportLinkMapper", { useClass: ReportLinkMapper });
container.register("DashboardLinkMapper", { useClass: DashboardLinkMapper });
container.register("FileLinkMapper", { useClass: FileLinkMapper });
// Data Sources
container.registerSingleton("CategoryDataSource", CategoryDataSourceLaravelImpl);
container.registerSingleton("DashboardDataSource", DashboardDataSourceLaravelImpl);
container.registerSingleton("ReportDataSource", ReportDataSourceLaravelImpl);
container.registerSingleton("CustomerDataSource", CustomerDataSourceLaravelImpl);
container.registerSingleton("CustomerTypeDataSource", CustomerTypeDataSourceLaravelImpl);
container.registerSingleton("FieldTypeDataSource", FieldTypeDataSourceLaravelImpl);
container.registerSingleton("SymvaroLaravelAuthDataSource", SymvaroLaravelAuthDataSource);
container.registerSingleton("ItemDataSource", ItemDataSourceLaravelImpl);
container.registerSingleton("SearchItemDataSource", SearchItemDataSourceLaravelImpl);
container.registerSingleton("ItemsListDataSource", ItemsListDataSourceLaravelImpl);
container.registerSingleton("TypeDataSource", TypeDataSourceLaravelImpl);
container.registerSingleton("HistoryDataSource", HistoryDataSourceLaravelImpl);
// Repositories
container.registerSingleton("CustomerTypeRepository", CustomerTypeRepositoryDefaultImpl);
container.registerSingleton("FieldTypeRepository", FieldTypeRepositoryDefaultImpl);
container.registerSingleton("category-repository", CategoryRepositoryDefaultImpl);
container.registerSingleton("customer-repository", CustomerRepositoryDefaultImpl);
container.registerSingleton("type-repository", TypeRepositoryDefaultImpl);
container.registerSingleton("items-list-repository", ItemsListRepositoryDefaultImpl);
container.registerSingleton("item-repository", ItemRepositoryDefaultImpl);
container.registerSingleton("SearchItemRepository", SearchItemRepositoryDefaultImpl);
container.registerSingleton("HistoryRepository", HistoryRepositoryDefaultImpl);
container.registerSingleton("dashboard-repository", DashboardRepositoryDefaultImpl);
container.registerSingleton("report-repository", ReportRepositoryDefaultImpl);
container.registerSingleton("auth-repository", AuthRepositoryGoogleSignInImpl);
container.registerSingleton("FileRepository", FileUploadRepositoryDefaultImpl);
loadFonts();
if (process.env.VUE_APP_GOOGLE_LOGIN_CLIENT_ID === undefined) {
    console.error("Missing Google Login Client Id: Add VUE_APP_GOOGLE_LOGIN_CLIENT_ID environment variable");
}
if (process.env.VUE_APP_SERVER_BASE_URL === undefined) {
    console.error("Missing Server Base URL: Add VUE_APP_SERVER_BASE_URL environment variable");
}
const vue3GoogleLoginOptions = { clientId: process.env.VUE_APP_GOOGLE_LOGIN_CLIENT_ID };
createApp(App)
    .use(vuetify)
    .use(router)
    .use(createPinia())
    .use(vue3GoogleLogin, vue3GoogleLoginOptions)
    .mount('#app');
