var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a;
import { inject, injectable } from "tsyringe";
import { IHttpClient } from "@/data/data-sources/http-client";
import { dateToInputString } from "@/utils/components-utils";
import { mapLinksFromList } from "@/data/utils";
export let ItemsListDataSourceLaravelImpl = class ItemsListDataSourceLaravelImpl {
    httpClient;
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    async readAll(httpRequestData, pagination, search, categoryId, customerId, typeId, available, state, startDate, endDate) {
        const searchData = {};
        if (search !== undefined)
            searchData.search = search;
        if (categoryId !== undefined)
            searchData.category_id = categoryId;
        if (customerId !== undefined)
            searchData.customer_id = customerId;
        if (typeId !== undefined)
            searchData.type_id = typeId;
        if (available !== undefined)
            searchData.available = available ? 1 : 0;
        if (state !== undefined)
            searchData.state = state;
        if (startDate !== undefined)
            searchData.start_date = dateToInputString(startDate);
        if (endDate !== undefined)
            searchData.end_date = dateToInputString(endDate);
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
            data: searchData,
            params: {
                limit: pagination.limit,
                page: pagination.page
            }
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        const data = result.data;
        return {
            links: mapLinksFromList(data.links),
            items: {
                page: pagination.page,
                limit: pagination.limit,
                size: data.size,
                totalItemsCount: data.total_items_count,
                items: data.items.map((value) => {
                    return {
                        data: {
                            id: value.id,
                            typeName: value.type_name,
                            idFieldValue: value.id_field_value,
                            idFieldDescription: value.id_field_description,
                            currentCustomerName: value.current_customer_name ?? undefined,
                            endOfRent: value.ended_at ? new Date(value.ended_at) : undefined,
                            state: value.state,
                            available: value.available,
                        },
                        relatedItemData: value.related_item ? {
                            id: value.related_item.id,
                            typeName: value.related_item.type_name,
                            idFieldValue: value.related_item.id_field_value,
                            idFieldDescription: value.id_field_description,
                            currentCustomerName: value.related_item.current_customer_name,
                            endOfRent: value.related_item.ended_at ? new Date(value.related_item.ended_at) : undefined,
                            state: value.related_item.state,
                            available: value.related_item.available,
                        } : undefined,
                    };
                })
            }
        };
    }
};
ItemsListDataSourceLaravelImpl = __decorate([
    injectable(),
    __param(0, inject("HttpClient")),
    __metadata("design:paramtypes", [typeof (_a = typeof IHttpClient !== "undefined" && IHttpClient) === "function" ? _a : Object])
], ItemsListDataSourceLaravelImpl);
