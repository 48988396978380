var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { inject, injectable } from "tsyringe";
import { IHistoryDataSource } from "@/data/data-sources/history/history-data-source";
import { HistoryLinkMapper } from "@/data/data-sources/link-mapper";
export let HistoryRepositoryDefaultImpl = class HistoryRepositoryDefaultImpl {
    dataSource;
    linkMapper;
    constructor(dataSource, linkMapper) {
        this.dataSource = dataSource;
        this.linkMapper = linkMapper;
    }
    async readItemHistory(id) {
        const link = this.linkMapper.generateLink("read-item-history", { id });
        return (await this.dataSource.readItemHistory({
            url: link.href,
            method: link.method,
        })).itemHistory;
    }
    async readCustomerHistory(id, typeId) {
        const link = this.linkMapper.generateLink("read-customer-history", { id });
        return (await this.dataSource.readCustomerHistory({
            url: link.href,
            method: link.method,
        }, typeId)).customerHistory;
    }
    async update(id, customerId, startDate, soldDate, endDate, returnDate, comment) {
        const link = this.linkMapper.generateLink("update", { id });
        return (await this.dataSource.update({
            url: link.href,
            method: link.method,
        }, customerId, startDate, soldDate, endDate, returnDate, comment)).historyEntry;
    }
    async delete(id) {
        const link = this.linkMapper.generateLink("delete", { id });
        return await this.dataSource.delete({
            url: link.href,
            method: link.method,
        });
    }
    async unsell(id) {
        const link = this.linkMapper.generateLink("unsell", { id });
        return this.dataSource.unsell({
            url: link.href,
            method: link.method,
        });
    }
};
HistoryRepositoryDefaultImpl = __decorate([
    injectable(),
    __param(0, inject("HistoryDataSource")),
    __param(1, inject("HistoryLinkMapper")),
    __metadata("design:paramtypes", [typeof (_a = typeof IHistoryDataSource !== "undefined" && IHistoryDataSource) === "function" ? _a : Object, typeof (_b = typeof HistoryLinkMapper !== "undefined" && HistoryLinkMapper) === "function" ? _b : Object])
], HistoryRepositoryDefaultImpl);
