export class GenericCache {
    items;
    hasCachedItems() {
        return this.items !== undefined;
    }
    setItems(items) {
        this.items = {};
        items.forEach(value => {
            this.items[this.getItemKey(value)] = value;
        });
    }
    getItem(key) {
        if (this.items === undefined)
            return undefined;
        return this.items[key];
    }
    setItem(item) {
        this.setItemWithKey(this.getItemKey(item), item);
    }
    setItemWithKey(key, update) {
        if (this.items === undefined) {
            this.items = { key: update };
        }
        else {
            this.items[key] = update;
        }
    }
    getAllItems() {
        if (this.items === undefined) {
            return undefined;
        }
        else {
            return Object.keys(this.items).map((key) => {
                return this.items[key];
            });
        }
    }
    removeItem(key) {
        if (this.items === undefined)
            return;
        delete this.items[key];
    }
}
