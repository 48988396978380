import { defineStore } from "pinia";
export const useSettingsTabBar = defineStore("settings-tab-bar", {
    state: () => {
        return {
            currentTab: "customers"
        };
    },
    actions: {
        setTab(tab) {
            if (["customers", "types"].includes(tab)) {
                this.currentTab = tab;
            }
        }
    }
});
