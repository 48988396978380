var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a;
import { inject, injectable } from "tsyringe";
import { IHttpClient } from "@/data/data-sources/http-client";
export let CategoryDataSourceLaravelImpl = class CategoryDataSourceLaravelImpl {
    httpClient;
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    async readAll() {
        const result = (await this.httpClient.request({ url: "/categories", method: "get" }));
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return result.data.data.map((value) => {
            return {
                category: {
                    id: value.id,
                    name: value.name
                }
            };
        });
    }
};
CategoryDataSourceLaravelImpl = __decorate([
    injectable(),
    __param(0, inject("HttpClient")),
    __metadata("design:paramtypes", [typeof (_a = typeof IHttpClient !== "undefined" && IHttpClient) === "function" ? _a : Object])
], CategoryDataSourceLaravelImpl);
