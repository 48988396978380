var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a;
import { googleTokenLogin, googleLogout } from "vue3-google-login";
import { inject, injectable } from "tsyringe";
import { SymvaroLaravelAuthDataSource } from "@/data/data-sources/auth/auth-data-source";
export let AuthRepositoryGoogleSignInImpl = class AuthRepositoryGoogleSignInImpl {
    dataSource;
    constructor(dataSource) {
        this.dataSource = dataSource;
    }
    async signIn() {
        const googleAuthResult = await googleTokenLogin();
        await this.dataSource.requestAndStoreBearerToken(googleAuthResult.access_token);
        return Promise.resolve({});
    }
    async signOut() {
        await googleLogout();
        await this.dataSource.clearBearerToken();
        return Promise.resolve();
    }
    async loadAuthState() {
        if (this.dataSource.hasValidLocalBearerToken()) {
            this.dataSource.setBearerTokenFromLocalStorage();
            return {};
        }
        else {
            return undefined;
        }
    }
};
AuthRepositoryGoogleSignInImpl = __decorate([
    injectable(),
    __param(0, inject("SymvaroLaravelAuthDataSource")),
    __metadata("design:paramtypes", [typeof (_a = typeof SymvaroLaravelAuthDataSource !== "undefined" && SymvaroLaravelAuthDataSource) === "function" ? _a : Object])
], AuthRepositoryGoogleSignInImpl);
