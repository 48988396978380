import DashboardView from "@/router/views/DashboardView.vue";
import AuthView from "@/router/views/AuthView.vue";
import ItemsListView from "@/router/views/ItemsListView.vue";
import AddItemView from "@/router/views/item-management/AddItemView.vue";
import UpdateItemView from "@/router/views/item-management/UpdateItemView.vue";
import GiveOutItemView from "@/router/views/item-management/GiveOutItemView.vue";
import ReturnItemView from "@/router/views/item-management/ReturnItemView.vue";
import ItemHistoryView from "@/router/views/ItemHistoryView.vue";
import ReportView from "@/router/views/ReportView.vue";
import SettingsView from "@/router/views/settings/SettingsView.vue";
import AddCustomerView from "@/router/views/settings/customer/AddCustomerView.vue";
import EditCustomerView from "@/router/views/settings/customer/EditCustomerView.vue";
import CustomerHistoryView from "@/router/views/settings/customer/CustomerHistoryView.vue";
import AddTypeView from "@/router/views/settings/type/AddTypeView.vue";
import EditTypeView from "@/router/views/settings/type/EditTypeView.vue";
export default [
    {
        path: "/",
        name: "dashboard",
        component: DashboardView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/auth",
        name: "auth",
        component: AuthView,
        meta: {
            authRequired: false
        }
    },
    {
        path: "/items",
        name: "items",
        component: ItemsListView,
        meta: {
            authRequired: true
        },
    },
    {
        path: "/items/add",
        name: "items/add",
        component: AddItemView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/items/update/:id",
        name: "items/update",
        component: UpdateItemView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/items/history/:id",
        name: "items/history",
        component: ItemHistoryView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/items/give-out",
        name: "items/give-out",
        component: GiveOutItemView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/items/return",
        name: "items/return",
        component: ReturnItemView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/report",
        name: "report",
        component: ReportView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/settings",
        name: "settings",
        component: SettingsView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/settings/customer/add",
        name: "settings/customer/add",
        component: AddCustomerView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/settings/customer/update/:id",
        name: "settings/customer/update",
        component: EditCustomerView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/settings/customer/history/:id",
        name: "settings/customer/history",
        component: CustomerHistoryView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/settings/type/add",
        name: "settings/type/add",
        component: AddTypeView,
        meta: {
            authRequired: true
        }
    },
    {
        path: "/settings/type/update/:id",
        name: "settings/type/update",
        component: EditTypeView,
        meta: {
            authRequired: true
        }
    }
];
