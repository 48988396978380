import NProgress from 'nprogress';
import { defineStore } from "pinia";
import { container } from "tsyringe";
import { loadAuthRequiredData } from "@/utils/store-utils";
export const useAuthStore = defineStore("auth", {
    state: () => {
        return {
            user: undefined,
            error: undefined,
            authStateLoaded: false
        };
    },
    actions: {
        async authenticate() {
            const authRepository = container.resolve("auth-repository");
            NProgress.start();
            await authRepository.signIn().then(async (value) => {
                this.user = value;
                await loadAuthRequiredData();
                this.authStateLoaded = true;
            }).catch(reason => {
                this.error = reason;
            }).finally(() => {
                NProgress.done();
            });
        },
        async logout() {
            const authRepository = container.resolve("auth-repository");
            NProgress.start();
            await authRepository.signOut().catch(reason => {
                this.error = reason;
            }).finally(() => {
                this.user = undefined;
                NProgress.done();
            });
        },
        clearError() {
            this.error = undefined;
        },
        async loadAuthState() {
            const authRepository = container.resolve("auth-repository");
            NProgress.start();
            await authRepository.loadAuthState().then(async (value) => {
                this.user = value;
                await loadAuthRequiredData();
                this.authStateLoaded = true;
            }).catch(reason => {
                this.error = reason;
            }).finally(() => {
                NProgress.done();
            });
        }
    },
    getters: {
        isAuthenticated: (state) => state.user != undefined
    }
});
