import { defineStore } from "pinia";
import { container } from "tsyringe";
export const useCustomerTypeStore = defineStore("customer-type", {
    state: () => {
        return {
            customerTypes: []
        };
    },
    actions: {
        async load() {
            const customerTypeRepository = container.resolve("CustomerTypeRepository");
            await customerTypeRepository.readAll().then(types => {
                this.customerTypes = types;
            });
        }
    },
    getters: {
        typeById: state => {
            return (id) => state.customerTypes.find(type => type.id === id);
        },
        defaultTypeCopy: state => {
            return JSON.parse(JSON.stringify(state.customerTypes[0]));
        }
    }
});
