<template>
  <v-card variant="outlined">
    <v-expansion-panels>
      <v-expansion-panel title="Filter">
        <template #text>
          <div class="mt-2">
            <slot />
          </div>
        </template>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
