import moment from "moment";
import { notEmptyRule, validDateRule } from "@/utils/text-field-rules";
import { ItemState } from "@/domain/entities";
export const iconForDataType = (type) => {
    switch (type) {
        case "email":
            return "mdi-at";
        case "link":
            return "mdi-link";
        case "number":
            return "mdi-numeric";
        case "date":
            return "mdi-calendar";
        case "selection":
            return "mdi-checkbox-marked-outline";
        default:
            return "mdi-file-document-outline";
    }
};
export const generateRules = (isRequired, isValidDate = false) => {
    const rules = [];
    if (isRequired) {
        rules.push(notEmptyRule);
    }
    if (isValidDate) {
        rules.push(validDateRule);
    }
    return rules;
};
export const rulesForField = (field) => {
    return generateRules(field.required, field.type === "date");
};
export const readableIdentificationFromItem = (item) => `${item.idFieldDescription}: ${item.idFieldValue}`;
export const formatDateToDDMMMYYYY = (date) => {
    return moment(date).format("DD. MMM YYYY");
};
export const dateToInputString = (date) => date.toJSON().slice(0, 10);
const dateInOneWeek = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
export const endOfRentDeadlines = [
    {
        title: "Jedes Mietende",
        dates: {
            startDate: undefined,
            endDate: undefined,
        },
    },
    {
        title: "Überfällig",
        dates: {
            startDate: undefined,
            endDate: new Date(),
        },
    },
    {
        title: "In einer Woche",
        dates: {
            startDate: new Date(),
            endDate: dateInOneWeek,
        },
    },
    {
        title: "In einem Monat",
        dates: {
            startDate: dateInOneWeek,
            endDate: new Date(new Date().getTime() + 31 * 24 * 60 * 60 * 1000),
        },
    },
];
export const itemStateAsString = (state) => {
    switch (state) {
        case ItemState.IN_STOCK:
            return "Im Lager";
        case ItemState.RENTED:
            return "Vermietet";
        case ItemState.SOLD:
            return "Verkauft";
        case ItemState.INACTIVE:
            return "Inaktiv";
        default:
            return "Unbekannt";
    }
};
export const fieldsToFieldValuesInputField = (fields) => {
    return fields.map((field) => {
        return {
            id: field.id,
            type: field.fieldType.type,
            selectionOptions: field.fieldType.selectionOptions,
            value: "",
            required: field.required,
            description: field.description,
        };
    });
};
export const itemToSelectedItem = (item) => {
    return {
        id: item.id,
        identification: readableIdentificationFromItem(item),
        typeName: item.type.name,
    };
};
