var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var ReportDataSourceLaravelImpl_1;
var _a;
import { inject, injectable } from "tsyringe";
import { IHttpClient } from "@/data/data-sources/http-client";
import { dateToInputString } from "@/utils/components-utils";
export let ReportDataSourceLaravelImpl = ReportDataSourceLaravelImpl_1 = class ReportDataSourceLaravelImpl {
    httpClient;
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    async readReport(httpRequestData, typeId, typeFieldsFilter, date, itemState) {
        const formattedDate = dateToInputString(date);
        const postData = { type_id: typeId, date: formattedDate };
        if (itemState)
            postData.state = itemState;
        if (typeFieldsFilter.length > 0)
            postData.type_fields_filter = typeFieldsFilter;
        const config = {
            url: httpRequestData.url,
            method: httpRequestData.method,
            data: postData
        };
        const result = (await this.httpClient.request(config));
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        const data = result.data;
        return {
            report: ReportDataSourceLaravelImpl_1.reportFromData(data)
        };
    }
    static reportFromData(data) {
        return {
            totalItemsCount: data.total_items_count,
            items: data.items.map((item) => {
                return {
                    type_name: item.type_name,
                    state: item.state,
                    fields: {
                        id_field: {
                            value: item.fields.id_field.value
                        },
                        other_fields: item.fields.other_fields.map((field) => {
                            return {
                                name: field.name,
                                value: field.value
                            };
                        })
                    },
                    issueDate: item.issue_date ? new Date(item.issue_date) : undefined
                };
            })
        };
    }
};
ReportDataSourceLaravelImpl = ReportDataSourceLaravelImpl_1 = __decorate([
    injectable(),
    __param(0, inject("HttpClient")),
    __metadata("design:paramtypes", [typeof (_a = typeof IHttpClient !== "undefined" && IHttpClient) === "function" ? _a : Object])
], ReportDataSourceLaravelImpl);
