var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var ItemDataSourceLaravelImpl_1;
var _a;
import { inject, injectable } from "tsyringe";
import { IHttpClient } from "@/data/data-sources/http-client";
import { dateToInputString } from "@/utils/components-utils";
import { mapLinksFromList } from "@/data/utils";
export let ItemDataSourceLaravelImpl = ItemDataSourceLaravelImpl_1 = class ItemDataSourceLaravelImpl {
    httpClient;
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    async create(httpRequestData, typeId, deliveredAt, relatedItemId, fileIds, fields) {
        const createData = {
            type_id: typeId,
            file_ids: fileIds
        };
        createData.delivered_at = dateToInputString(deliveredAt);
        if (relatedItemId !== undefined)
            createData.related_item_id = relatedItemId;
        createData.fields = fields.map(field => {
            return { id: field.id, value: field.value };
        });
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
            data: createData
        });
        if (result.status !== 201)
            return Promise.reject(result.statusText);
        return ItemDataSourceLaravelImpl_1.mapResponseToItemDao(result.data.data);
    }
    async delete(httpRequestData) {
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
        });
        if (result.status !== 204)
            return Promise.reject(result.statusText);
        return Promise.resolve();
    }
    async giveOut(httpRequestData, itemIds, customerId, sell, startDate, returnDate) {
        const giveOutData = {
            item_ids: itemIds,
            customer_id: customerId,
            sell: sell
        };
        if (startDate !== undefined)
            giveOutData.start_date = dateToInputString(startDate);
        if (returnDate !== undefined)
            giveOutData.end_date = dateToInputString(returnDate);
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
            data: giveOutData
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return Promise.resolve();
    }
    async readRelatedItem(httpRequestData) {
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return ItemDataSourceLaravelImpl_1.mapResponseToRelatedItemDao(result.data.related_item);
    }
    async readFiles(httpRequestData) {
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return { files: ItemDataSourceLaravelImpl_1.mapFilesFromList(result.data) };
    }
    async readReturnDetails(httpRequestData) {
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return ItemDataSourceLaravelImpl_1.mapResponseToReturnItemDetailsDao(result.data);
    }
    async readSingle(httpRequestData) {
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return ItemDataSourceLaravelImpl_1.mapResponseToItemDao(result.data.data);
    }
    async return(httpRequestData, itemIds, returnDate, comment) {
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
            data: {
                item_ids: itemIds,
                return_date: dateToInputString(returnDate),
                comment: comment
            }
        });
        if (result.status !== 201)
            return Promise.reject(result.statusText);
        return Promise.resolve();
    }
    async update(httpRequestData, isAvailable, relatedItemId, deliveredAt, fileIds, fields) {
        const updateData = {};
        if (isAvailable !== undefined)
            updateData.is_available = isAvailable;
        if (relatedItemId !== undefined)
            updateData.related_item_id = relatedItemId;
        if (deliveredAt !== undefined)
            updateData.delivered_at = dateToInputString(deliveredAt);
        if (fileIds !== undefined)
            updateData.file_ids = fileIds;
        if (fields !== undefined)
            updateData.fields = fields.map(field => {
                return {
                    id: field.id,
                    value: field.value
                };
            });
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
            data: updateData
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return ItemDataSourceLaravelImpl_1.mapResponseToItemDao(result.data.data);
    }
    static mapResponseToItemDao(data) {
        return {
            item: {
                id: data.id,
                isAvailable: data.is_available === 1,
                deliveredAt: new Date(data.delivered_at),
                idFieldDescription: data.id_field_description,
                idFieldValue: data.id_field_value,
                relatedItem: data.related_item ? {
                    id: data.related_item.id,
                    typeName: data.related_item.type_name,
                    idFieldDescription: data.related_item.id_field_description,
                    idFieldValue: data.related_item.id_field_value,
                } : undefined,
                type: {
                    id: data.type.id,
                    name: data.type.name,
                    categoryId: data.type.category_id,
                    idFieldId: data.type.id_field_id,
                },
                fields: data.fields.map((field) => {
                    return {
                        id: field.id,
                        value: field.field_type.type === "selection" ? field.value.id : field.value,
                        fieldType: {
                            id: field.field_type.id,
                            type: field.field_type.type,
                            description: field.field_type.description,
                            selectionOptions: field.field_type.selection_options
                        },
                        description: field.description,
                        required: field.required === 1,
                    };
                }),
                rentalsCount: data.rentals_count ?? 0,
                files: data.files ? data.files.map((file) => {
                    return {
                        id: file.id,
                        name: file.name,
                        url: file.url,
                        linkCount: file.link_count,
                    };
                }) : undefined
            },
            links: mapLinksFromList(data.links),
        };
    }
    static mapFilesFromList(files) {
        return files.map((file) => {
            return {
                id: file.id,
                name: file.name,
                url: file.url,
                linkCount: file.link_count,
            };
        });
    }
    static mapResponseToRelatedItemDao(data) {
        if (data == undefined)
            return undefined;
        return {
            relatedItem: {
                id: data.id,
                typeName: data.type_name,
                idFieldDescription: data.id_field_description,
                idFieldValue: data.id_field_value,
            }
        };
    }
    static mapResponseToReturnItemDetailsDao(data) {
        return {
            returnItemDetails: {
                id: data.id,
                relatedItem: data.related_item ? {
                    id: data.related_item.id,
                    typeName: data.related_item.type_name,
                    idFieldDescription: data.related_item.id_field_description,
                    idFieldValue: data.related_item.id_field_value,
                } : undefined,
                endDate: new Date(data.end_date),
            }
        };
    }
};
ItemDataSourceLaravelImpl = ItemDataSourceLaravelImpl_1 = __decorate([
    injectable(),
    __param(0, inject("HttpClient")),
    __metadata("design:paramtypes", [typeof (_a = typeof IHttpClient !== "undefined" && IHttpClient) === "function" ? _a : Object])
], ItemDataSourceLaravelImpl);
