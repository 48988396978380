import { defineStore } from "pinia";
import { container } from "tsyringe";
import NProgress from "nprogress";
export const useTypeStore = defineStore("type", {
    state: () => {
        return {
            error: undefined,
            types: undefined,
            search: undefined
        };
    },
    actions: {
        async create(name, categoryId, idFieldIndex, fields) {
            const typeRepository = container.resolve("type-repository");
            NProgress.start();
            await typeRepository.create(name, categoryId, idFieldIndex, fields).then(value => {
                this.clearError();
                if (this.types) {
                    this.types = [value, ...this.types];
                }
                else {
                    this.types = [value];
                }
            }).catch(reason => {
                this.error = reason;
            }).finally(() => {
                NProgress.done();
            });
        },
        async load() {
            const typeRepository = container.resolve("type-repository");
            NProgress.start();
            await typeRepository.readAll(this.search).then(value => {
                this.clearError();
                this.types = value;
            }).catch(reason => {
                this.error = reason;
            }).finally(() => {
                NProgress.done();
            });
        },
        async update(id, name, categoryId, fields) {
            const typeRepository = container.resolve("type-repository");
            NProgress.start();
            await typeRepository.update(id, name, categoryId, fields).then(value => {
                this.clearError();
                if (this.types) {
                    const indexOfOldItem = this.types.findIndex(type => type.id === id);
                    this.types[indexOfOldItem] = value;
                }
                else {
                    this.types = [value];
                }
            }).catch(reason => {
                this.error = reason;
            }).finally(() => {
                NProgress.done();
            });
        },
        async delete(id) {
            const typeRepository = container.resolve("type-repository");
            NProgress.start();
            await typeRepository.delete(id).then(() => {
                this.clearError();
                if (this.types) {
                    const typeIndex = this.types.findIndex(type => type.id === id);
                    this.types.splice(typeIndex, 1);
                }
            }).catch(reason => {
                this.error = reason;
            }).finally(() => {
                NProgress.done();
            });
        },
        clearError() {
            this.error = undefined;
        }
    },
    getters: {
        isLoaded: (state) => state.types !== undefined && state.error === undefined,
        hasError: (state) => state.error !== undefined,
        typeById: (state) => {
            return async (id) => {
                const cachedType = state.types?.find(type => type.id === id);
                if (cachedType) {
                    return cachedType;
                }
                else {
                    const typeRepository = container.resolve("type-repository");
                    return await typeRepository.readSingle(id);
                }
            };
        }
    }
});
