var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a;
import { IHttpClient } from "@/data/data-sources/http-client";
import { inject, injectable } from "tsyringe";
import { ItemState } from "@/domain/entities";
export let DashboardDataSourceLaravelImpl = class DashboardDataSourceLaravelImpl {
    httpClient;
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    async read(httpRequestData) {
        const result = (await this.httpClient.request({ url: httpRequestData.url, method: httpRequestData.method }));
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return {
            dashboard: {
                stocks: Object.keys(result.data.stocks).map((name) => {
                    const stock = result.data.stocks[name];
                    return {
                        state: this.itemStateFromStock(name),
                        types: stock.types.map((type) => {
                            return {
                                id: type.id,
                                name: type.name,
                                count: type.total_count
                            };
                        }),
                        totalCount: stock.total_count
                    };
                }),
                categories: result.data.categories.map((value) => {
                    return {
                        id: value.id,
                        name: value.name,
                        overdue: value.overdue,
                        inOneWeek: value.in_one_week,
                        inOneMonth: value.in_one_month
                    };
                })
            }
        };
    }
    itemStateFromStock(stock) {
        switch (stock) {
            case "in_stock":
                return ItemState.IN_STOCK;
            case "rented":
                return ItemState.RENTED;
            case "sold":
                return ItemState.SOLD;
            case "inactive":
                return ItemState.INACTIVE;
            default:
                return undefined;
        }
    }
};
DashboardDataSourceLaravelImpl = __decorate([
    injectable(),
    __param(0, inject("HttpClient")),
    __metadata("design:paramtypes", [typeof (_a = typeof IHttpClient !== "undefined" && IHttpClient) === "function" ? _a : Object])
], DashboardDataSourceLaravelImpl);
