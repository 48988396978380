var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
import { inject, injectable } from "tsyringe";
import { ICustomerDataSource } from "@/data/data-sources/customer/customer-data-source";
import { CustomerCache } from "@/data/cache/customer-cache";
import { CustomerLinkCache } from "@/data/cache/link-cache";
import { linkToHttpRequestData } from "@/data/utils";
import { CustomerDaoLinkMapper } from "@/data/data-sources/link-mapper";
export let CustomerRepositoryDefaultImpl = class CustomerRepositoryDefaultImpl {
    customerDataSource;
    customerCache;
    linkCache;
    linkMapper;
    constructor(customerDataSource, customerCache, linkCache, linkMapper) {
        this.customerDataSource = customerDataSource;
        this.customerCache = customerCache;
        this.linkCache = linkCache;
        this.linkMapper = linkMapper;
    }
    async create(email, name, externalLink, customerTypeId) {
        const httpData = linkToHttpRequestData(this.linkCache.getItemWithFallback("create"));
        const result = await this.customerDataSource.create(httpData, email, name, externalLink, customerTypeId);
        this.customerCache.setItem(result);
        return result.customer;
    }
    async delete(id) {
        const customer = this.customerCache.getItem(id);
        if (!customer)
            return Promise.reject(`Could not find customer with id ${id} in cache`);
        const link = this.linkMapper.typeLinkByAction("delete", customer);
        await this.customerDataSource.delete(linkToHttpRequestData(link));
        return Promise.resolve();
    }
    async readAll(search, customerTypeId) {
        const link = this.linkCache.getItemWithFallback("read-all");
        const result = await this.customerDataSource.readAll(linkToHttpRequestData(link), search, customerTypeId);
        this.customerCache.setItems(result.customers);
        this.linkCache.setItems(result.links);
        return result.customers.map(value => value.customer);
    }
    async readSingle(id) {
        const itemInCache = this.customerCache.getItem(id);
        const link = itemInCache ? this.linkMapper.typeLinkByAction("read", itemInCache) : this.linkMapper.generateLink("read", { id });
        const result = await this.customerDataSource.readSingle(linkToHttpRequestData(link));
        this.customerCache.setItem(result);
        return result.customer;
    }
    async update(id, email, name, externalLink, customerTypeId) {
        const itemInCache = this.customerCache.getItem(id);
        const link = itemInCache ? this.linkMapper.typeLinkByAction("update", itemInCache) : this.linkMapper.generateLink("update", { id });
        const result = await this.customerDataSource.update(linkToHttpRequestData(link), email, name, externalLink, customerTypeId);
        this.customerCache.setItem(result);
        return result.customer;
    }
};
CustomerRepositoryDefaultImpl = __decorate([
    injectable(),
    __param(0, inject("CustomerDataSource")),
    __param(1, inject("CustomerCache")),
    __param(2, inject("CustomerLinkCache")),
    __param(3, inject("CustomerDaoLinkMapper")),
    __metadata("design:paramtypes", [typeof (_a = typeof ICustomerDataSource !== "undefined" && ICustomerDataSource) === "function" ? _a : Object, typeof (_b = typeof CustomerCache !== "undefined" && CustomerCache) === "function" ? _b : Object, typeof (_c = typeof CustomerLinkCache !== "undefined" && CustomerLinkCache) === "function" ? _c : Object, typeof (_d = typeof CustomerDaoLinkMapper !== "undefined" && CustomerDaoLinkMapper) === "function" ? _d : Object])
], CustomerRepositoryDefaultImpl);
