var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { inject, injectable } from "tsyringe";
import { CategoryDataSourceLaravelImpl } from "@/data/data-sources/category/category-data-source-laravel-impl";
import { CategoryCache } from "@/data/cache/category-cache";
export let CategoryRepositoryDefaultImpl = class CategoryRepositoryDefaultImpl {
    categoryDataSource;
    categoryCache;
    constructor(categoryDataSource, categoryCache) {
        this.categoryDataSource = categoryDataSource;
        this.categoryCache = categoryCache;
    }
    async readAll() {
        if (!this.categoryCache.hasCachedItems()) {
            await this.readAndCacheAllItems();
        }
        return this.categoryCache.getAllItems()?.map(value => value.category) ?? [];
    }
    async readAndCacheAllItems() {
        await this.categoryDataSource.readAll().then(value => {
            this.categoryCache.setItems(value);
        });
    }
};
CategoryRepositoryDefaultImpl = __decorate([
    injectable(),
    __param(0, inject("CategoryDataSource")),
    __param(1, inject("CategoryCache")),
    __metadata("design:paramtypes", [typeof (_a = typeof CategoryDataSourceLaravelImpl !== "undefined" && CategoryDataSourceLaravelImpl) === "function" ? _a : Object, typeof (_b = typeof CategoryCache !== "undefined" && CategoryCache) === "function" ? _b : Object])
], CategoryRepositoryDefaultImpl);
