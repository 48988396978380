var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { DashboardDataSourceLaravelImpl } from "@/data/data-sources/dashboard/dashboard-data-source-laravel-impl";
import { inject, injectable } from "tsyringe";
import { DashboardLinkMapper } from "@/data/data-sources/link-mapper";
import { linkToHttpRequestData } from "@/data/utils";
export let DashboardRepositoryDefaultImpl = class DashboardRepositoryDefaultImpl {
    dashboardDataSource;
    linkMapper;
    constructor(dashboardDataSource, linkMapper) {
        this.dashboardDataSource = dashboardDataSource;
        this.linkMapper = linkMapper;
    }
    async read() {
        const httpRequestData = linkToHttpRequestData(this.linkMapper.generateLink("read"));
        return (await this.dashboardDataSource.read(httpRequestData)).dashboard;
    }
};
DashboardRepositoryDefaultImpl = __decorate([
    injectable(),
    __param(0, inject("DashboardDataSource")),
    __param(1, inject("DashboardLinkMapper")),
    __metadata("design:paramtypes", [typeof (_a = typeof DashboardDataSourceLaravelImpl !== "undefined" && DashboardDataSourceLaravelImpl) === "function" ? _a : Object, typeof (_b = typeof DashboardLinkMapper !== "undefined" && DashboardLinkMapper) === "function" ? _b : Object])
], DashboardRepositoryDefaultImpl);
