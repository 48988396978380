<template>
  <v-row class="mt-3">
    <v-col
        cols="12"
        md="4"
        lg="4"
        class="pb-0">
      <slot name="left"/>
    </v-col>
    <v-col
        cols="12"
        md="8"
        lg="8">
      <slot name="right"/>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
</script>

<style scoped>

</style>