var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { inject, injectable } from "tsyringe";
import { ISearchItemDataSource } from "@/data/data-sources/search-item/search-item-data-source";
import { SearchItemDaoLinkMapper } from "@/data/data-sources/link-mapper";
export let SearchItemRepositoryDefaultImpl = class SearchItemRepositoryDefaultImpl {
    searchItemDataSource;
    linkMapper;
    constructor(searchItemDataSource, linkMapper) {
        this.searchItemDataSource = searchItemDataSource;
        this.linkMapper = linkMapper;
    }
    async search(search, hideCurrentlyRentedItems, hideCurrentlyNotRentedItems, hideItemsWithRelation, hiddenItemIds) {
        const link = this.linkMapper.generateLink("read-all");
        const result = (await this.searchItemDataSource.search({
            url: link.href,
            method: link.method,
        }, search, hideCurrentlyRentedItems, hideCurrentlyNotRentedItems, hideItemsWithRelation));
        const searchTypes = result.map((value) => value.searchType);
        searchTypes.forEach((searchType) => {
            searchType.items = searchType.items.filter((item) => !hiddenItemIds.includes(item.id));
        });
        return searchTypes.filter((searchType) => searchType.items.length > 0);
    }
};
SearchItemRepositoryDefaultImpl = __decorate([
    injectable(),
    __param(0, inject("SearchItemDataSource")),
    __param(1, inject("SearchItemDaoLinkMapper")),
    __metadata("design:paramtypes", [typeof (_a = typeof ISearchItemDataSource !== "undefined" && ISearchItemDataSource) === "function" ? _a : Object, typeof (_b = typeof SearchItemDaoLinkMapper !== "undefined" && SearchItemDaoLinkMapper) === "function" ? _b : Object])
], SearchItemRepositoryDefaultImpl);
