var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { inject, injectable } from "tsyringe";
import { IHttpClient } from "@/data/data-sources/http-client";
import { FileLinkMapper } from "@/data/data-sources/link-mapper";
export let FileUploadRepositoryDefaultImpl = class FileUploadRepositoryDefaultImpl {
    httpClient;
    fileLinkMapper;
    constructor(httpClient, fileLinkMapper) {
        this.httpClient = httpClient;
        this.fileLinkMapper = fileLinkMapper;
    }
    async deleteFile(id) {
        const link = this.fileLinkMapper.generateLink("delete", { id });
        const result = await this.httpClient.request({
            url: link.href,
            method: link.method,
        });
        if (result.status !== 204)
            return Promise.reject(result.statusText);
        return Promise.resolve();
    }
    async uploadFile(file, name, callback) {
        const link = this.fileLinkMapper.generateLink("upload");
        const formData = new FormData();
        formData.append("file", file);
        formData.append("name", name);
        const result = await this.httpClient.request({
            url: link.href,
            method: link.method,
            data: formData,
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                if (callback) {
                    callback(Math.round(progress) / 100);
                }
            }
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return {
            id: result.data.id,
            name: result.data.name,
            url: result.data.url,
            linkCount: result.data.link_count
        };
    }
};
FileUploadRepositoryDefaultImpl = __decorate([
    injectable(),
    __param(0, inject("HttpClient")),
    __param(1, inject("FileLinkMapper")),
    __metadata("design:paramtypes", [typeof (_a = typeof IHttpClient !== "undefined" && IHttpClient) === "function" ? _a : Object, typeof (_b = typeof FileLinkMapper !== "undefined" && FileLinkMapper) === "function" ? _b : Object])
], FileUploadRepositoryDefaultImpl);
