import { defineStore } from "pinia";
import { container } from "tsyringe";
import NProgress from "nprogress";
export const useItemsListStore = defineStore("items-list", {
    state: () => {
        return {
            error: undefined,
            items: undefined,
            totalItemsCount: undefined,
            page: 0,
            limit: 10,
            size: 0,
            isLoading: false,
            filter: {
                search: undefined,
                categoryId: undefined,
                customer: undefined,
                state: undefined,
                typeId: undefined,
                isAvailable: undefined,
                filterDates: {
                    startDate: undefined,
                    endDate: undefined
                }
            }
        };
    },
    actions: {
        clearError() {
            this.error = undefined;
        },
        async loadInitialPage() {
            this.page = 0;
            return this.load();
        },
        async load() {
            const itemsListRepository = container.resolve("items-list-repository");
            NProgress.start();
            this.isLoading = true;
            const params = this.loadParams;
            await itemsListRepository.readAll(params.search, {
                limit: this.limit,
                page: this.page
            }, params.categoryId, params.customerId, params.typeId, params.available, params.state, params.startDate, params.endDate).then(value => {
                this.clearError();
                this.totalItemsCount = value.totalItemsCount;
                this.items = value.items;
                this.page = value.page;
                this.limit = value.limit;
                this.size = value.size;
            }).catch(reason => {
                this.error = reason;
            }).finally(() => {
                this.isLoading = false;
                NProgress.done();
            });
        },
        async loadNextPage() {
            this.page++;
            await this.load();
        },
        async loadPreviousPage() {
            this.page--;
            await this.load();
        }
    },
    getters: {
        isLoaded: (state) => state.items !== undefined && state.error === undefined,
        hasError: (state) => state.error !== undefined,
        hasNextPage: (state) => state.page < state.size - 1,
        hasPreviousPage: (state) => state.page >= 1,
        loadParams(state) {
            return {
                categoryId: state.filter.categoryId,
                customerId: state.filter.customer?.id,
                typeId: state.filter.typeId,
                state: state.filter.state,
                search: state.filter.search,
                available: state.filter.isAvailable,
                startDate: state.filter.filterDates.startDate,
                endDate: state.filter.filterDates.endDate
            };
        }
    }
});
