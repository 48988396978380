var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var SymvaroLaravelAuthDataSource_1;
var _a, _b;
import { inject, injectable } from "tsyringe";
import { IHttpClient, SymvaroLaravelAuthHttpClient } from "@/data/data-sources/http-client";
export let SymvaroLaravelAuthDataSource = class SymvaroLaravelAuthDataSource {
    static { SymvaroLaravelAuthDataSource_1 = this; }
    httpClient;
    authHttpClient;
    static BEARER_KEY = "bearer";
    constructor(httpClient, authHttpClient) {
        this.httpClient = httpClient;
        this.authHttpClient = authHttpClient;
    }
    async requestAndStoreBearerToken(googleAccessToken) {
        const bearerObject = await this.requestBearerObject(googleAccessToken);
        await this.storeAndSetBearerToken(bearerObject);
    }
    async requestBearerObject(googleAccessToken) {
        const result = await this.authHttpClient.request({
            url: "/login",
            method: "post",
            data: {
                "access-token": googleAccessToken
            }
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return result.data;
    }
    async storeAndSetBearerToken(bearerObject) {
        this.setBearerToken(bearerObject.bearer);
        SymvaroLaravelAuthDataSource_1.storeBearerObject(bearerObject);
    }
    async clearBearerToken() {
        this.setBearerToken(undefined);
        localStorage.removeItem(SymvaroLaravelAuthDataSource_1.BEARER_KEY);
    }
    hasValidLocalBearerToken() {
        if (localStorage.getItem(SymvaroLaravelAuthDataSource_1.BEARER_KEY) !== null) {
            const bearerObject = JSON.parse(localStorage.getItem(SymvaroLaravelAuthDataSource_1.BEARER_KEY));
            return new Date(bearerObject.expires_at).getTime() > new Date().getTime();
        }
        return false;
    }
    setBearerTokenFromLocalStorage() {
        const bearerObject = JSON.parse(localStorage.getItem(SymvaroLaravelAuthDataSource_1.BEARER_KEY));
        this.setBearerToken(bearerObject.bearer);
    }
    static storeBearerObject(bearerObject) {
        localStorage.setItem(SymvaroLaravelAuthDataSource_1.BEARER_KEY, JSON.stringify(bearerObject));
    }
    setBearerToken(bearerToken) {
        this.httpClient.apiAccess = bearerToken;
    }
};
SymvaroLaravelAuthDataSource = SymvaroLaravelAuthDataSource_1 = __decorate([
    injectable(),
    __param(0, inject("HttpClient")),
    __param(1, inject("AuthHttpClient")),
    __metadata("design:paramtypes", [typeof (_a = typeof IHttpClient !== "undefined" && IHttpClient) === "function" ? _a : Object, typeof (_b = typeof SymvaroLaravelAuthHttpClient !== "undefined" && SymvaroLaravelAuthHttpClient) === "function" ? _b : Object])
], SymvaroLaravelAuthDataSource);
