export function mapLinksFromList(list) {
    return list.map((value) => {
        return {
            href: value.href,
            method: value.method,
            action: value.action
        };
    });
}
export function linkToHttpRequestData(link) {
    return {
        url: link.href,
        method: link.method
    };
}
