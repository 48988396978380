export class ILinkMapper {
    linkByAction(action, links, fallbackProps) {
        return (links.find((value) => value.action === action) ??
            this.generateLink(action, fallbackProps));
    }
}
export class ITypeLinkMapper extends ILinkMapper {
    typeLinkByAction(action, type) {
        return this.linkByAction(action, this.linksFromType(type), this.fallbackPropsFromType(type));
    }
}
export class CustomerDaoLinkMapper extends ITypeLinkMapper {
    generateLink(action, props) {
        switch (action) {
            case "read":
                return {
                    href: `/customers/${props.id}`,
                    action: "read",
                    method: "get",
                };
            case "read-all":
                return {
                    href: "/customers",
                    action: "read-all",
                    method: "get",
                };
            default:
                throw `No fallback link for action ${action}`;
        }
    }
    linksFromType(type) {
        return type.links;
    }
    fallbackPropsFromType(type) {
        return { id: type.customer.id };
    }
}
export class CustomerTypeLinkMapper extends ILinkMapper {
    generateLink(action) {
        switch (action) {
            case "read-all":
                return {
                    href: "/customer-types",
                    action: "read-all",
                    method: "get",
                };
            default:
                throw `No fallback link for action ${action}`;
        }
    }
}
export class FieldTypeLinkMapper extends ILinkMapper {
    generateLink(action) {
        switch (action) {
            case "read-all":
                return {
                    href: "/field-types",
                    action: "read-all",
                    method: "get",
                };
            default:
                throw `No fallback link for action ${action}`;
        }
    }
}
export class SearchItemDaoLinkMapper extends ILinkMapper {
    generateLink(action) {
        switch (action) {
            case "read-all":
                return {
                    href: "/item/search",
                    action: "read-all",
                    method: "post",
                };
            default:
                throw `No fallback link for action ${action}`;
        }
    }
}
export class HistoryLinkMapper extends ILinkMapper {
    generateLink(action, props) {
        switch (action) {
            case "read-item-history":
                return {
                    href: `/history/item/${props.id}`,
                    action: "read-item-history",
                    method: "get",
                };
            case "read-customer-history":
                return {
                    href: `/history/customer/${props.id}`,
                    action: "read-customer-history",
                    method: "post",
                };
            case "update":
                return {
                    href: `/history/update/${props.id}`,
                    action: "update",
                    method: "post",
                };
            case "delete":
                return {
                    href: `/history/${props.id}`,
                    action: "delete",
                    method: "delete",
                };
            case "unsell":
                return {
                    href: `/history/unsell/${props.id}`,
                    action: "unsell",
                    method: "post",
                };
            default:
                throw `No fallback link for action ${action}`;
        }
    }
}
export class ItemDaoLinkMapper extends ITypeLinkMapper {
    generateLink(action, props) {
        switch (action) {
            case "read":
                return {
                    href: `/items/${props.id}`,
                    action: "read",
                    method: "get",
                };
            case "create":
                return {
                    href: `/items/create`,
                    action: "create",
                    method: "post",
                };
            case "return-details":
                return {
                    href: `/items/return/details/${props.id}`,
                    action: "return-details",
                    method: "get",
                };
            case "return":
                return {
                    href: "/items/return/",
                    action: "return",
                    method: "post",
                };
            case "give-out":
                return {
                    href: "/items/give-out/",
                    action: "give-out",
                    method: "post",
                };
            case "update":
                return {
                    href: `/items/${props.id}`,
                    action: "update",
                    method: "put",
                };
            case "delete":
                return {
                    href: `/items/delete/${props.id}`,
                    action: "delete",
                    method: "delete",
                };
            case "related-item":
                return {
                    href: `/items/related/${props.id}`,
                    action: "related-item",
                    method: "get",
                };
            case "files":
                return {
                    href: `/items/${props.id}/files`,
                    action: "files",
                    method: "get",
                };
            default:
                throw `No fallback link for action ${action}`;
        }
    }
    linksFromType(type) {
        return type.links;
    }
    fallbackPropsFromType(type) {
        return { id: type.item.id };
    }
}
export class ItemsListItemDaoLinkMapper extends ILinkMapper {
    generateLink(action) {
        switch (action) {
            case "read-all":
                return {
                    href: `/items`,
                    action: "read-all",
                    method: "post",
                };
            default:
                throw `No fallback link for action ${action}`;
        }
    }
}
export class TypeDaoLinkMapper extends ITypeLinkMapper {
    generateLink(action, props) {
        switch (action) {
            case "create":
                return {
                    href: `/types/create`,
                    action: "create",
                    method: "post",
                };
            case "read-by-category":
                return {
                    href: `/types-by-category`,
                    action: "read-by-category",
                    method: "post",
                };
            case "read-all":
                return {
                    href: `/types`,
                    action: "read-all",
                    method: "post",
                };
            case "read":
                return {
                    href: `/types/${props.id}`,
                    action: "read",
                    method: "get",
                };
            case "update":
                return {
                    href: `/types/${props.id}`,
                    action: "update",
                    method: "put",
                };
            case "delete":
                return {
                    href: `/types/${props.id}`,
                    action: "delete",
                    method: "delete",
                };
            default:
                throw `No fallback link for action ${action}`;
        }
    }
    linksFromType(type) {
        return type.links;
    }
    fallbackPropsFromType(type) {
        return { id: type.type.id };
    }
}
export class ReportLinkMapper extends ILinkMapper {
    generateLink(action) {
        switch (action) {
            case "read":
                return {
                    href: "/report",
                    action: "read",
                    method: "post",
                };
            default:
                throw `No fallback link for action ${action}`;
        }
    }
}
export class DashboardLinkMapper extends ILinkMapper {
    generateLink(action) {
        switch (action) {
            case "read":
                return {
                    href: "/dashboard",
                    action: "read",
                    method: "get",
                };
            default:
                throw `No fallback link for action ${action}`;
        }
    }
}
export class FileLinkMapper extends ILinkMapper {
    generateLink(action, props) {
        switch (action) {
            case "upload":
                return {
                    href: "/files/upload",
                    action: "upload",
                    method: "post",
                };
            case "delete":
                return {
                    href: `/files/delete/${props.id}`,
                    action: "delete",
                    method: "delete",
                };
            default:
                throw `No fallback link for action ${action}`;
        }
    }
}
