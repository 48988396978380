var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var TypeDataSourceLaravelImpl_1;
var _a;
import { inject, injectable } from "tsyringe";
import { IHttpClient } from "@/data/data-sources/http-client";
export let TypeDataSourceLaravelImpl = TypeDataSourceLaravelImpl_1 = class TypeDataSourceLaravelImpl {
    httpClient;
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    async create(httpRequestData, name, categoryId, idFieldIndex, fields) {
        const createData = {};
        createData.name = name;
        createData.category_id = categoryId;
        createData.id_field_index = idFieldIndex;
        createData.fields = fields.map((createField) => {
            return {
                field_type_id: createField.fieldTypeId,
                description: createField.description,
                required: createField.required ? 1 : 0
            };
        });
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
            data: createData
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return TypeDataSourceLaravelImpl_1.mapResponseToTypeDao(result.data.data);
    }
    async delete(httpRequestData) {
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method
        });
        if (result.status !== 204)
            return Promise.reject(result.statusText);
        return Promise.resolve();
    }
    async readAll(httpRequestData, search) {
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
            data: { search: search }
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        const data = result.data.data;
        return {
            types: data.types.map((data) => TypeDataSourceLaravelImpl_1.mapResponseToTypeDao(data)),
            links: data.links.map((link) => {
                return {
                    href: link.href,
                    method: link.method,
                    action: link.action
                };
            })
        };
    }
    async readByCategory(httpRequestData, categoryId) {
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
            data: { category_id: categoryId }
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        const data = result.data.data;
        return {
            typesByCategory: {
                categoryId: data.category_id,
                types: data.types.map((type) => {
                    return {
                        id: type.id,
                        name: type.name,
                    };
                })
            }
        };
    }
    async readSingle(httpRequestData) {
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return TypeDataSourceLaravelImpl_1.mapResponseToTypeDao(result.data.data);
    }
    async update(httpRequestData, name, categoryId, fields) {
        const updateData = {};
        if (name !== undefined)
            updateData.name = name;
        if (categoryId !== undefined)
            updateData.category_id = categoryId;
        updateData.fields = fields.map((updateField) => {
            return {
                id: updateField.id,
                field_type_id: updateField.fieldTypeId,
                description: updateField.description,
                required: updateField.required ? 1 : 0
            };
        });
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
            data: updateData
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return TypeDataSourceLaravelImpl_1.mapResponseToTypeDao(result.data.data);
    }
    static mapResponseToTypeDao(data) {
        return {
            type: {
                id: data.id,
                name: data.name,
                idFieldId: data.id_field_id,
                category: {
                    id: data.category.id,
                    name: data.category.name
                },
                fields: data.fields.map((field) => {
                    return {
                        id: field.id,
                        description: field.description,
                        required: field.required === 1,
                        fieldType: {
                            id: field.field_type.id,
                            type: field.field_type.type,
                            description: field.field_type.description,
                            selectionOptions: field.field_type.selection_options
                        }
                    };
                })
            },
            links: data.links.map((link) => {
                return {
                    href: link.href,
                    method: link.method,
                    action: link.action
                };
            })
        };
    }
};
TypeDataSourceLaravelImpl = TypeDataSourceLaravelImpl_1 = __decorate([
    injectable(),
    __param(0, inject("HttpClient")),
    __metadata("design:paramtypes", [typeof (_a = typeof IHttpClient !== "undefined" && IHttpClient) === "function" ? _a : Object])
], TypeDataSourceLaravelImpl);
