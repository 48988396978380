import axios from "axios";
export class IHttpClient {
    apiAccess;
    getBaseUrl() {
        return process.env.VUE_APP_SERVER_BASE_URL;
    }
}
export class SymvaroLaravelHttpClient extends IHttpClient {
    async request(config) {
        const baseUrl = config.url.startsWith("http:") || config.url.startsWith("https:") ? "" : this.getBaseUrl();
        return axios({
            baseURL: baseUrl,
            url: config.url,
            method: config.method,
            headers: {
                "Authorization": "Bearer " + this.apiAccess,
                "Accept": "application/json",
                "Access-Control-Allow-Origin": process.env.VUE_APP_SERVER_BASE_URL,
                ...config.headers
            },
            params: config.params,
            data: config.data,
            onUploadProgress: config.onUploadProgress
        });
    }
}
export class SymvaroLaravelAuthHttpClient extends IHttpClient {
    request(config) {
        return axios({
            baseURL: this.getBaseUrl(),
            url: config.url,
            method: config.method,
            data: config.data,
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        });
    }
}
