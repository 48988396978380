import { useCustomerTypeStore } from "@/store/data/customer-type";
import { useFieldTypeStore } from "@/store/data/field-type";
import { useCategoryStore } from "@/store/data/category";
export async function loadAuthRequiredData() {
    const customerTypeStore = useCustomerTypeStore();
    const fieldTypesStore = useFieldTypeStore();
    const categoryStore = useCategoryStore();
    return Promise.allSettled([
        customerTypeStore.load(),
        fieldTypesStore.load(),
        categoryStore.load()
    ]);
}
