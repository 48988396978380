import { defineStore } from "pinia";
import { container } from "tsyringe";
export const useCategoryStore = defineStore("category", {
    state: () => {
        return {
            categories: []
        };
    },
    actions: {
        async load() {
            const categoryRepository = container.resolve("category-repository");
            await categoryRepository.readAll().then(value => this.categories = value);
        }
    },
    getters: {
        defaultCategory: (state) => state.categories[0]
    }
});
