<template>
  <v-progress-circular
      class="mx-auto"
      :size="50"
      color="primary"
      :indeterminate="true"/>
</template>

<script lang="ts" setup>
</script>

<style scoped>

</style>