var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { inject, injectable } from "tsyringe";
import { ReportDataSourceLaravelImpl } from "@/data/data-sources/report/report-data-source-laravel-impl";
import { ReportLinkMapper } from "@/data/data-sources/link-mapper";
import { linkToHttpRequestData } from "@/data/utils";
export let ReportRepositoryDefaultImpl = class ReportRepositoryDefaultImpl {
    reportDataSource;
    linkMapper;
    constructor(reportDataSource, linkMapper) {
        this.reportDataSource = reportDataSource;
        this.linkMapper = linkMapper;
    }
    async readReport(typeId, typeFieldsFilter, date, itemState) {
        const httpRequestData = linkToHttpRequestData(this.linkMapper.generateLink("read"));
        return (await this.reportDataSource.readReport(httpRequestData, typeId, typeFieldsFilter, date, itemState)).report;
    }
};
ReportRepositoryDefaultImpl = __decorate([
    injectable(),
    __param(0, inject("ReportDataSource")),
    __param(1, inject("ReportLinkMapper")),
    __metadata("design:paramtypes", [typeof (_a = typeof ReportDataSourceLaravelImpl !== "undefined" && ReportDataSourceLaravelImpl) === "function" ? _a : Object, typeof (_b = typeof ReportLinkMapper !== "undefined" && ReportLinkMapper) === "function" ? _b : Object])
], ReportRepositoryDefaultImpl);
