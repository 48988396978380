import { GenericCache } from "@/data/cache/generic-cache";
import { CustomerDaoLinkMapper, ItemDaoLinkMapper, TypeDaoLinkMapper } from "@/data/data-sources/link-mapper";
export class ILinkCache extends GenericCache {
    getItemKey(item) {
        return item.action;
    }
    getItemWithFallback(key) {
        const itemFromCache = this.getItem(key);
        if (itemFromCache) {
            return itemFromCache;
        }
        else {
            return this.generateFallback(key);
        }
    }
}
export class CustomerLinkCache extends ILinkCache {
    linkMapper;
    constructor(linkMapper = new CustomerDaoLinkMapper()) {
        super();
        this.linkMapper = linkMapper;
    }
    generateFallback(key) {
        return this.linkMapper.generateLink(key);
    }
}
export class ItemLinkCache extends ILinkCache {
    linkMapper;
    constructor(linkMapper = new ItemDaoLinkMapper()) {
        super();
        this.linkMapper = linkMapper;
    }
    generateFallback(key) {
        return this.linkMapper.generateLink(key);
    }
}
export class TypeLinkCache extends ILinkCache {
    linkMapper;
    constructor(linkMapper = new TypeDaoLinkMapper()) {
        super();
        this.linkMapper = linkMapper;
    }
    generateFallback(key) {
        return this.linkMapper.generateLink(key);
    }
}
