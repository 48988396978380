import NProgress from 'nprogress';
import { createRouter, createWebHistory } from "vue-router";
import routes from "@/router/routes";
import { useAuthStore } from "@/store/auth";
const router = createRouter({
    routes,
    history: createWebHistory(),
});
router.beforeEach(async (to, from, next) => {
    if (from.name !== undefined) {
        NProgress.start();
    }
    const authRequired = to.matched.some(value => value.meta.authRequired);
    const authStore = useAuthStore();
    if (!authRequired) {
        next();
    }
    else {
        if (!authStore.authStateLoaded) {
            await authStore.loadAuthState();
        }
        if (authStore.isAuthenticated) {
            next();
        }
        else {
            next({ name: "auth" });
        }
    }
});
router.afterEach(() => {
    NProgress.done();
});
export default router;
