var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var CustomerDataSourceLaravelImpl_1;
var _a;
import { inject, injectable } from "tsyringe";
import { IHttpClient } from "@/data/data-sources/http-client";
import { mapLinksFromList } from "@/data/utils";
export let CustomerDataSourceLaravelImpl = CustomerDataSourceLaravelImpl_1 = class CustomerDataSourceLaravelImpl {
    httpClient;
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    async create(httpRequestData, email, name, externalLink, customerTypeId) {
        const createData = {
            email: email,
            name: name,
            external_link: externalLink,
            customer_type_id: customerTypeId
        };
        const result = await this.httpClient.request({ url: httpRequestData.url, data: createData, method: "post" });
        if (result.status !== 201)
            return Promise.reject(result.statusText);
        const data = result.data.data;
        return CustomerDataSourceLaravelImpl_1.mapResponseToCustomerDao(data);
    }
    async delete(httpRequestData) {
        const result = await this.httpClient.request({ url: httpRequestData.url, method: httpRequestData.method });
        if (result.status !== 204)
            return Promise.reject(result.statusText);
    }
    async readAll(httpRequestData, search, customerTypeId) {
        const readData = {};
        if (search !== undefined)
            readData.search = search;
        if (customerTypeId !== undefined)
            readData.customer_type_id = customerTypeId;
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
            params: readData
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        const responseData = result.data;
        return {
            customers: responseData.data.customers.map((customer) => {
                return CustomerDataSourceLaravelImpl_1.mapResponseToCustomerDao(customer);
            }),
            links: mapLinksFromList(responseData.data.links)
        };
    }
    async readSingle(httpRequestData) {
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        const responseData = result.data;
        return CustomerDataSourceLaravelImpl_1.mapResponseToCustomerDao(responseData.data);
    }
    async update(httpRequestData, email, name, externalLink, customerTypeId) {
        const updateData = {};
        if (email !== undefined)
            updateData.email = email;
        if (name !== undefined)
            updateData.name = name;
        if (externalLink !== undefined)
            updateData.external_link = externalLink;
        if (customerTypeId !== undefined)
            updateData.customer_type_id = customerTypeId;
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
            data: updateData
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return CustomerDataSourceLaravelImpl_1.mapResponseToCustomerDao(result.data.data);
    }
    static mapResponseToCustomerDao(response) {
        return {
            customer: {
                id: response.id,
                rentalsCount: response.rentals_count,
                name: response.name,
                email: response.email,
                externalLink: response.external_link,
                customerType: {
                    id: response.customer_type.id,
                    type: response.customer_type.type
                }
            },
            links: mapLinksFromList(response.links)
        };
    }
};
CustomerDataSourceLaravelImpl = CustomerDataSourceLaravelImpl_1 = __decorate([
    injectable(),
    __param(0, inject("HttpClient")),
    __metadata("design:paramtypes", [typeof (_a = typeof IHttpClient !== "undefined" && IHttpClient) === "function" ? _a : Object])
], CustomerDataSourceLaravelImpl);
