import { defineStore } from "pinia";
import { container } from "tsyringe";
export const useFieldTypeStore = defineStore("field-type", {
    state: () => {
        return {
            fieldTypes: []
        };
    },
    actions: {
        async load() {
            const fieldTypeRepository = container.resolve("FieldTypeRepository");
            this.fieldTypes = await fieldTypeRepository.readAll();
        }
    },
    getters: {
        defaultFieldTypeCopy: (state) => JSON.parse(JSON.stringify(state.fieldTypes[0]))
    }
});
