var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var HistoryDataSourceLaravelImpl_1;
var _a;
import { inject, injectable } from "tsyringe";
import { IHttpClient } from "@/data/data-sources/http-client";
import { dateToInputString } from "@/utils/components-utils";
export let HistoryDataSourceLaravelImpl = HistoryDataSourceLaravelImpl_1 = class HistoryDataSourceLaravelImpl {
    httpClient;
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    async delete(httpRequestData) {
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
        });
        if (result.status !== 204)
            return Promise.reject(result.statusText);
        return Promise.resolve();
    }
    async readItemHistory(httpRequestData) {
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        const data = result.data.data;
        return {
            itemHistory: {
                item: {
                    id: data.item.id,
                    typeName: data.item.type_name,
                    idFieldValue: data.item.id_field_value,
                    idFieldDescription: data.item.id_field_description,
                },
                entries: data.history.map(HistoryDataSourceLaravelImpl_1.mapToItemHistoryEntry),
            },
        };
    }
    async readCustomerHistory(httpRequestData, typeId) {
        const readData = {};
        if (typeId)
            readData["type_id"] = typeId;
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
            data: readData,
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        const data = result.data.data;
        return {
            customerHistory: {
                customerName: data.customer_name,
                overdueCount: data.overdue_count,
                rentedCount: data.rented_count,
                soldCount: data.sold_count,
                entries: data.history.map(HistoryDataSourceLaravelImpl_1.mapToCustomerHistoryEntry),
            },
        };
    }
    async update(httpRequestData, customerId, startDate, soldDate, endDate, returnDate, comment) {
        const updateData = { comment: comment };
        if (customerId)
            updateData["customer_id"] = customerId;
        if (startDate != undefined)
            updateData["start_date"] = dateToInputString(startDate);
        if (soldDate != undefined)
            updateData["sold_date"] = dateToInputString(soldDate);
        if (endDate != undefined)
            updateData["end_date"] = dateToInputString(endDate);
        if (returnDate != undefined)
            updateData["return_date"] = dateToInputString(returnDate);
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
            data: updateData,
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        const data = result.data.data;
        return {
            historyEntry: HistoryDataSourceLaravelImpl_1.mapToItemHistoryEntry(data),
        };
    }
    async unsell(httpRequestData) {
        const result = await this.httpClient.request({
            url: httpRequestData.url,
            method: httpRequestData.method,
        });
        if (result.status !== 200)
            return Promise.reject(result.statusText);
        return Promise.resolve();
    }
    static mapToItemHistoryEntry(data) {
        return {
            customer: {
                id: data.customer.id,
                name: data.customer.name,
            },
            comment: data.comment != undefined ? data.comment : "",
            entry: HistoryDataSourceLaravelImpl_1.mapToHistoryEntry(data),
        };
    }
    static mapToCustomerHistoryEntry(data) {
        return {
            item: {
                id: data.item.id,
                typeName: data.item.type_name,
                idFieldValue: data.item.id_field_value,
                idFieldDescription: data.item.id_field_description,
            },
            entry: HistoryDataSourceLaravelImpl_1.mapToHistoryEntry(data),
        };
    }
    static mapToHistoryEntry(data) {
        return {
            id: data.id,
            startDate: data.start_date != undefined ? new Date(data.start_date) : undefined,
            soldDate: data.sold_date != undefined ? new Date(data.sold_date) : undefined,
            endDate: data.end_date != undefined ? new Date(data.end_date) : undefined,
            returnDate: data.return_date != undefined ? new Date(data.return_date) : undefined,
            returnDateDifference: data.return_date_difference != undefined
                ? parseInt(data.return_date_difference)
                : undefined,
        };
    }
};
HistoryDataSourceLaravelImpl = HistoryDataSourceLaravelImpl_1 = __decorate([
    injectable(),
    __param(0, inject("HttpClient")),
    __metadata("design:paramtypes", [typeof (_a = typeof IHttpClient !== "undefined" && IHttpClient) === "function" ? _a : Object])
], HistoryDataSourceLaravelImpl);
