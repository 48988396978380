var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
import { inject, injectable } from "tsyringe";
import { IItemDataSource } from "@/data/data-sources/item/item-data-source";
import { ItemDaoLinkMapper } from "@/data/data-sources/link-mapper";
import { ItemLinkCache } from "@/data/cache/link-cache";
import { linkToHttpRequestData } from "@/data/utils";
import { ItemCache } from "@/data/cache/item-cache";
export let ItemRepositoryDefaultImpl = class ItemRepositoryDefaultImpl {
    itemDataSource;
    itemCache;
    linkCache;
    linkMapper;
    constructor(itemDataSource, itemCache, linkCache, linkMapper) {
        this.itemDataSource = itemDataSource;
        this.itemCache = itemCache;
        this.linkCache = linkCache;
        this.linkMapper = linkMapper;
    }
    async create(typeId, deliveredAt, relatedItemId, fileIds, fields) {
        const httpData = linkToHttpRequestData(this.linkCache.getItemWithFallback("create"));
        const result = await this.itemDataSource.create(httpData, typeId, deliveredAt, relatedItemId, fileIds, fields);
        this.itemCache.setItem(result);
        return result.item;
    }
    async readSingle(id) {
        const item = this.itemCache.getItem(id);
        const link = item !== undefined ? this.linkMapper.typeLinkByAction("read", item) : this.linkMapper.generateLink("read", { id });
        const result = await this.itemDataSource.readSingle(linkToHttpRequestData(link));
        this.itemCache.setItem(result);
        return result.item;
    }
    async readReturnDetails(id) {
        const link = this.linkMapper.generateLink("return-details", { id });
        const result = await this.itemDataSource.readReturnDetails(linkToHttpRequestData(link));
        return result.returnItemDetails;
    }
    async readRelatedItem(id) {
        const link = this.linkMapper.generateLink("related-item", { id });
        return (await this.itemDataSource.readRelatedItem(linkToHttpRequestData(link)))?.relatedItem;
    }
    async readFiles(id) {
        const link = this.linkMapper.generateLink("files", { id });
        const result = await this.itemDataSource.readFiles(linkToHttpRequestData(link));
        return result.files;
    }
    async return(itemIds, returnDate, comment) {
        const link = this.linkMapper.generateLink("return");
        return this.itemDataSource.return(linkToHttpRequestData(link), itemIds, returnDate, comment);
    }
    async giveOut(itemIds, customerId, sell, startDate, returnDate) {
        const link = this.linkMapper.generateLink("give-out");
        return this.itemDataSource.giveOut(linkToHttpRequestData(link), itemIds, customerId, sell, startDate, returnDate);
    }
    async update(id, isAvailable, relatedItemId, deliveredAt, fileIds, fields) {
        const item = this.itemCache.getItem(id);
        const link = item !== undefined ? this.linkMapper.typeLinkByAction("update", item) : this.linkMapper.generateLink("update", { id });
        const result = await this.itemDataSource.update(linkToHttpRequestData(link), isAvailable, relatedItemId, deliveredAt, fileIds, fields);
        this.itemCache.setItem(result);
        return result.item;
    }
    async delete(id) {
        const item = this.itemCache.getItem(id);
        const link = item !== undefined ? this.linkMapper.typeLinkByAction("delete", item) : this.linkMapper.generateLink("delete", { id });
        await this.itemDataSource.delete(linkToHttpRequestData(link));
        this.itemCache.removeItem(id);
        return Promise.resolve();
    }
};
ItemRepositoryDefaultImpl = __decorate([
    injectable(),
    __param(0, inject("ItemDataSource")),
    __param(1, inject("ItemCache")),
    __param(2, inject("ItemLinkCache")),
    __param(3, inject("ItemDaoLinkMapper")),
    __metadata("design:paramtypes", [typeof (_a = typeof IItemDataSource !== "undefined" && IItemDataSource) === "function" ? _a : Object, typeof (_b = typeof ItemCache !== "undefined" && ItemCache) === "function" ? _b : Object, typeof (_c = typeof ItemLinkCache !== "undefined" && ItemLinkCache) === "function" ? _c : Object, typeof (_d = typeof ItemDaoLinkMapper !== "undefined" && ItemDaoLinkMapper) === "function" ? _d : Object])
], ItemRepositoryDefaultImpl);
