<template>
  <v-card
    variant="outlined"
    class="d-flex flex-row-reverse"
    color="rgba(0,0,0,0.2)"
  >
    <div class="pa-3">
      <slot />
    </div>
  </v-card>
</template>
