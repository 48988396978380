var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
import { inject, injectable } from "tsyringe";
import { ITypeDataSource } from "@/data/data-sources/type/type-data-source";
import { TypeCache } from "@/data/cache/type-cache";
import { TypeLinkCache } from "@/data/cache/link-cache";
import { TypeDaoLinkMapper } from "@/data/data-sources/link-mapper";
import { linkToHttpRequestData } from "@/data/utils";
export let TypeRepositoryDefaultImpl = class TypeRepositoryDefaultImpl {
    typeDataSource;
    typeCache;
    typeLinkCache;
    typeLinkMapper;
    constructor(typeDataSource, typeCache, typeLinkCache, typeLinkMapper) {
        this.typeDataSource = typeDataSource;
        this.typeCache = typeCache;
        this.typeLinkCache = typeLinkCache;
        this.typeLinkMapper = typeLinkMapper;
    }
    async create(name, categoryId, idFieldIndex, fields) {
        const link = this.typeLinkCache.getItemWithFallback("create");
        const result = await this.typeDataSource.create(linkToHttpRequestData(link), name, categoryId, idFieldIndex, fields);
        this.typeCache.setItem(result);
        return result.type;
    }
    async readByCategory(categoryId) {
        const link = this.typeLinkMapper.generateLink("read-by-category");
        return (await this.typeDataSource.readByCategory(linkToHttpRequestData(link), categoryId)).typesByCategory;
    }
    async readAll(search) {
        const link = this.typeLinkCache.getItemWithFallback("read-all");
        const result = await this.typeDataSource.readAll(linkToHttpRequestData(link), search);
        this.typeCache.setItems(result.types);
        this.typeLinkCache.setItems(result.links);
        return result.types.map(type => type.type);
    }
    async readSingle(id) {
        const item = this.typeCache.getItem(id);
        const link = item !== undefined ? this.typeLinkMapper.typeLinkByAction("read", item) : this.typeLinkMapper.generateLink("read", { id });
        const result = await this.typeDataSource.readSingle(linkToHttpRequestData(link));
        this.typeCache.setItem(result);
        this.typeLinkCache.setItems(result.links);
        return result.type;
    }
    async update(id, name, categoryId, fields) {
        const item = this.typeCache.getItem(id);
        const link = item !== undefined ? this.typeLinkMapper.typeLinkByAction("update", item) : this.typeLinkMapper.generateLink("update", { id });
        const result = await this.typeDataSource.update(linkToHttpRequestData(link), name, categoryId, fields);
        this.typeCache.setItem(result);
        return result.type;
    }
    async delete(id) {
        const item = this.typeCache.getItem(id);
        const link = item !== undefined ? this.typeLinkMapper.typeLinkByAction("delete", item) : this.typeLinkMapper.generateLink("delete", { id });
        await this.typeDataSource.delete(linkToHttpRequestData(link));
        this.typeCache.removeItem(id);
        return Promise.resolve();
    }
};
TypeRepositoryDefaultImpl = __decorate([
    injectable(),
    __param(0, inject("TypeDataSource")),
    __param(1, inject("TypeCache")),
    __param(2, inject("TypeLinkCache")),
    __param(3, inject("TypeDaoLinkMapper")),
    __metadata("design:paramtypes", [typeof (_a = typeof ITypeDataSource !== "undefined" && ITypeDataSource) === "function" ? _a : Object, typeof (_b = typeof TypeCache !== "undefined" && TypeCache) === "function" ? _b : Object, typeof (_c = typeof TypeLinkCache !== "undefined" && TypeLinkCache) === "function" ? _c : Object, typeof (_d = typeof TypeDaoLinkMapper !== "undefined" && TypeDaoLinkMapper) === "function" ? _d : Object])
], TypeRepositoryDefaultImpl);
